import React, { useEffect } from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faFacebookF } from '@fortawesome/free-brands-svg-icons'

export default function Layout({ children }) {

  useEffect(() => {
    document.querySelector('[data-bs-toggle="offcanvas"]').addEventListener('click', function () {
        document.querySelector('.offcanvas-collapse').classList.toggle('open');
      });    
  })

  return (
    <div>
        <header className="container navbar-header">
            <a className="navbar-brand" href="/"><img src="/logo.svg" alt="Leeds City Juniors FC" title="LCJ Home" /></a>
            <button className="navbar-toggler p-0 border-0 float-end" type="button" data-bs-toggle="offcanvas" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"><FontAwesomeIcon className="lcj-color" icon={faBars}></FontAwesomeIcon></span>
            </button> 
            <div id="header-social">
                <a href="https://twitter.com/LeedsCityJnrs" target="_blank" title="LCJ Twitter Feed"><FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon></a>
                <a href="https://www.facebook.com/Leeds-City-Juniors-FC-269833143048618" target="_blank" title="LCJ Facebook Page"><FontAwesomeIcon icon={faFacebookF}></FontAwesomeIcon></a>
            </div>
            <div className="club-name">
                <div className="club-name-lcj">Leeds City Juniors FC</div>
                <div className="club-name-est">EST. 2006</div>
            </div>
        </header>

        <div className="nav-container">
            <div className="container clearfix container-menu">
            <nav className="navbar navbar-expand-lg float-end" aria-label="Main navigation">
                <div className="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item active">
                        <Link className="nav-link" aria-current="page" to="/" title="Home">Home</Link>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="dropdown01" data-bs-toggle="dropdown" aria-expanded="false">About</a>
                        <ul className="dropdown-menu" aria-labelledby="dropdown01">
                            <li><Link className='dropdown-item' to="/history" title="History">History</Link></li>
                            <li><Link className='dropdown-item' to="/honours" title="Honours">Honours</Link></li>
                            <li><Link className='dropdown-item' to="/club-officials" title="Club Officials">Club Officials</Link></li>
                            <li><Link className='dropdown-item' to="/policies" title="Club policies">Policies</Link></li>
                            <li><Link className='dropdown-item' to="/sponsors" title="Sponsors">Sponsors</Link></li>
                            <li><Link className='dropdown-item' to="/boot-exchange-program" title="Boot Exchange Programme">Boot Exchange Programme</Link></li>
                        </ul>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="dropdown01" data-bs-toggle="dropdown" aria-expanded="false">Teams</a>
                        <ul className="dropdown-menu" aria-labelledby="dropdown01">
                            <li><Link className='dropdown-item' to="/team-boys/kindergarten" title="Kindergarten (4-7)">Kindergarten (age 4-7)</Link></li>
                            <li><Link className='dropdown-item' to="/junior-teams" title="Junior Teams (8-10)">Junior Teams (8-18)</Link></li>
                            <li><Link className='dropdown-item' to="/team-girls/allstars" title="Girls All Stars (4-12)">Girls All Stars (4-12)</Link></li>
                            <li><Link className='dropdown-item' to="/girls-teams" title="Girls Teams (8-17)">Girls Teams (8-17)</Link></li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="https://www.leedscityfc.co.uk/" target="_blank" title="Senior Section">Senior Section</a>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/findus" title="Find Us">Find Us</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/contact" title="Contact Us">Contact</Link>
                    </li>
                </ul>
                <div className="nav-link-social">
                    <a href="https://twitter.com/LeedsCityJnrs" target="_blank" title="LCJ Twitter Feed"><FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon></a>
                    <a href="https://www.facebook.com/Leeds-City-Juniors-FC-269833143048618" target="_blank" title="LCJ Facebook Page"><FontAwesomeIcon icon={faFacebookF}></FontAwesomeIcon></a>
                </div>
                </div>
            </nav>
            </div>
        </div>
       
        <main>
        {children}
        </main>

        <footer>
            <span>&copy; Leeds City Juniors FC</span>
            <a href="https://twitter.com/LeedsCityJnrs" target="_blank" title="LCJ Twitter Feed"><FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon></a>
            <a href="https://www.facebook.com/Leeds-City-Juniors-FC-269833143048618" target="_blank" title="LCJ Facebook Page"><FontAwesomeIcon icon={faFacebookF}></FontAwesomeIcon></a>
        </footer>
    </div>
  );
}